import { LatLngLiteral } from 'app/business-logic/domain-models/Facility';
import { GisModels } from 'app/business-logic/services/model-service';
import { IModellingInterval } from 'app/business-logic/services/modelling-service/types';
import { INamedEntity } from 'app/business-logic/services/types';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchAlertPointReports } from './fetchAlertPointReports';
import { fetchEmissionSources } from './fetchEmissionSources';
import {
  fetchAlertPointReportsFulfilled,
  fetchAlertPointReportsPending,
  fetchAlertPointReportsRejected,
} from './reducers';
import {
  fetchEmissionSourcesFulfilled,
  fetchEmissionSourcesPending,
  fetchEmissionSourcesRejected,
} from './reducers/emission-sources';
import { AlertPointReportsRequest, EmissionSourcesRequest } from './types';
import { resetStore } from '../actions';

export type ModellingState = {
  configuration: {
    activeModels: Guid[];
    autoUpdate: boolean;
    dateTime: number;
    emissionSources: boolean;
    modelFrom: number;
    /**
     * @deprecated
     * "models" is only used in site configuration and shouldn't be used elsewhere.
     * Use "useModelList" to get available models.
     */
    models: INamedEntity[];
    modelTo: number;
    selectedIntervalIndex: number;
  };
  data: {
    alertPointReports: AlertPointReportsRequest;
    emissionSources: EmissionSourcesRequest;
  };
  map: {
    controls: {
      domainBoundaries: boolean;
    };
  };
};

export interface DataPoint {
  id: Guid;
  points: LatLngLiteral[];
}

export const INITIAL_STATE: ModellingState = {
  configuration: {
    activeModels: [],
    autoUpdate: false,
    dateTime: DateTime.now().startOf('hour').toMillis(),
    emissionSources: true,
    modelFrom: 0,
    models: [],
    modelTo: 0,
    selectedIntervalIndex: 0,
  },
  data: {
    alertPointReports: { status: 'NotRequested' },
    emissionSources: { status: 'NotRequested' },
  },
  map: {
    controls: {
      domainBoundaries: true,
    },
  },
};

export type Configuration = ModellingState['configuration'];

export const slice = createSlice({
  name: 'modelling',
  initialState: INITIAL_STATE,
  reducers: {
    setDomainBoundariesMapControl: (state, { payload }: PayloadAction<boolean>) => {
      state.map.controls.domainBoundaries = payload;
    },

    setConfiguration: (state, { payload }: PayloadAction<Partial<Configuration>>) => {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...payload,
        },
      };
    },

    toggleAutoUpdate: state => {
      state.configuration.autoUpdate = !state.configuration.autoUpdate;
      state.configuration.activeModels = [];
    },

    modelsLoaded: (state, { payload }: PayloadAction<GisModels>) => {
      const models = Object.entries(payload).map(([id, name]) => ({ id, name }));
      state.configuration.models = models;
    },

    modelCreatedOrModified: (state, { payload }: PayloadAction<INamedEntity>) => {
      const isModifying = !!state.configuration.models.find(({ id }) => id === payload.id);
      if (isModifying) {
        state.configuration.models = state.configuration.models.map(model => {
          if (model.id !== payload.id) return model;
          return payload;
        });
      } else {
        state.configuration.models = [...state.configuration.models, payload];
      }
    },

    modelDeleted: (state, { payload }: PayloadAction<Guid>) => {
      if (payload.length) {
        state.configuration.models = state.configuration.models.filter(({ id }) => id !== payload);
      }
    },

    displayEmissionSourcesToggled: state => {
      state.configuration.emissionSources = !state.configuration.emissionSources;
    },

    /**
     * Sets the modelling time and rounds it to the nearest valid time,
     * based on the selected modelling interval, e.g. "10 minute" or "60 minute"
     */
    modellingTimeChanged: (
      state,
      {
        payload: { dateTime, selectedModellingInterval },
      }: PayloadAction<{ dateTime: DateTime; selectedModellingInterval: IModellingInterval }>
    ) => {
      const intervalDuration = selectedModellingInterval?.durationInMinutes ?? 60;
      const nearestMinute = Math.floor(dateTime.minute / intervalDuration) * intervalDuration;

      state.configuration.dateTime = dateTime.set({ minute: nearestMinute, second: 0, millisecond: 0 }).valueOf();
    },

    selectedIntervalChanged: (state, { payload }: PayloadAction<number>) => {
      state.configuration.selectedIntervalIndex = payload;
      state.configuration.activeModels = [];
    },

    toggleActiveModel: (state, { payload }: PayloadAction<Guid>) => {
      const shouldToggleOff = state.configuration.activeModels.includes(payload);
      if (shouldToggleOff) {
        state.configuration.activeModels = state.configuration.activeModels.filter(modelId => modelId !== payload);
      } else {
        state.configuration.activeModels = state.configuration.activeModels.concat(payload);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAlertPointReports.pending, fetchAlertPointReportsPending)
      .addCase(fetchAlertPointReports.fulfilled, fetchAlertPointReportsFulfilled)
      .addCase(fetchAlertPointReports.rejected, fetchAlertPointReportsRejected);

    builder
      .addCase(fetchEmissionSources.pending, fetchEmissionSourcesPending)
      .addCase(fetchEmissionSources.fulfilled, fetchEmissionSourcesFulfilled)
      .addCase(fetchEmissionSources.rejected, fetchEmissionSourcesRejected);

    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
