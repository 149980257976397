import { HTMLAttributes, useRef, useState } from 'react';
import { Slider, SliderProps } from 'app/components/playback/slider/Slider';
import { TipFormatter } from './SliderWithTooltip.types';
import { Position, Tooltip } from '@blueprintjs/core';
import {
  SliderTooltipContent as TooltipContent,
  SliderWithTooltipHandle as Handle,
  SliderWithTooltipHandleInner as HandleInner,
} from './SliderWithTooltip.styles';

export type SliderWithTooltipProps = SliderProps & {
  tipFormatter?: TipFormatter;
};

export const SliderWithTooltip = (props: SliderWithTooltipProps) => {
  const { tipFormatter, ...rest } = props;
  const { value } = props;
  return (
    <Slider
      handleRender={({ props }) => <CustomHandle renderProps={props} value={value} tipFormatter={tipFormatter} />}
      {...rest}
    />
  );
};

function CustomHandle({
  renderProps,
  tipFormatter,
  value,
}: {
  renderProps: HTMLAttributes<HTMLDivElement>;
  tipFormatter?: TipFormatter;
  value?: number | undefined;
}) {
  const { className } = renderProps;
  const handleRef = useRef<HTMLDivElement>(null);
  const content = tipFormatter && typeof value === 'number' ? tipFormatter(value) : value?.toString();
  const [isHoveringHandle, setIsHoveringHandle] = useState(false);
  const isOpen = isHoveringHandle || className?.includes('rc-slider-handle-dragging');

  return (
    <>
      <Handle
        {...renderProps}
        ref={handleRef}
        onMouseOver={() => setIsHoveringHandle(true)}
        onMouseOut={() => setIsHoveringHandle(false)}
      >
        <Tooltip
          content={<TooltipContent>{content}</TooltipContent>}
          isOpen={isOpen}
          position={Position.TOP}
          usePortal={false}
        >
          {/**
           * Need to wrap tooltip around an inner component so it's positioned
           * correctly, otherwise it remains at the handle's initial position
           * instead of following as the user drags it along
           */}
          <HandleInner />
        </Tooltip>
      </Handle>
    </>
  );
}
