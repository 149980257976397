import { Classes, Tooltip } from '@blueprintjs/core';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useAlertResponseCategories } from 'app/views/configuration/pages/alert/alert-responses/alert-response-form/useAlertResponseCategories';
import { useI18n } from 'core/hooks/useI18n';
import { useRouting } from 'core/routing/Routing';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';
import { ComponentProps, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useAlertById } from '../../hooks';
import { getVariable } from '../../pages/alert-history/use-alert-history/formatAlertHistory';
import { LOADING_ALERT } from './ViewAlert.constants';
import {
  ViewAlertHeaderItemDescription as Description,
  ViewAlertHeaderItem as Item,
  ViewAlertHeaderItems as Items,
  NewWindowIcon,
  OpenAlert,
  StyledViewAlertHeader,
  ViewAlertHeaderItemThreshold as Threshold,
  ViewAlertHeaderItemTitle as Title,
  ViewAlertHeaderTruncatedItem as TruncatedItem,
  ViewAlertHeaderItemValue as Value,
} from './ViewAlertHeader.styles';
import { useAppDispatch } from 'app/redux/store';
import { deselectAlert } from 'app/redux/ui/actions';

type ViewAlertHeaderProps = { selectedAlertId: string } & ComponentProps<typeof StyledViewAlertHeader>;

export const ViewAlertHeader = ({ selectedAlertId, ...rest }: ViewAlertHeaderProps) => {
  const { l10n } = useI18n('app/views/alerts', 'alertHeader');
  const {
    data: { date, time, description, alertType, monitorName, displayUnit, threshold, highestValue },
    isLoading,
    isSuccess,
  } = useAlertDetails(selectedAlertId);
  const className = isLoading ? Classes.SKELETON : '';
  const {
    routes: { alerts },
    generatePath,
  } = useRouting();
  const viewAlertLink = generatePath(alerts.view(), { alertId: selectedAlertId });
  const isViewingAlert = !!useMatch(viewAlertLink);
  const dispatch = useAppDispatch();
  const handleDeselectAlert = () => {
    dispatch(deselectAlert());
  };

  if (!isLoading && !isSuccess) return null;

  return (
    <StyledViewAlertHeader {...rest}>
      <Items>
        <Item>
          <Title className={className}>{l10n('monitorName')}</Title>
          <Description className={className}>{monitorName}</Description>
        </Item>
        <Item>
          <Title className={className}>{l10n('alertType')}</Title>
          <Description className={className}>{alertType}</Description>
        </Item>
        <Item>
          <Title className={className}>{l10n('alertDate')}</Title>
          <Description className={className}>{date}</Description>
        </Item>
        <Item>
          <Title className={className}>{l10n('alertTime')}</Title>
          <Description className={className}>{time}</Description>
        </Item>
        {typeof threshold !== 'undefined' && (
          <Item>
            <Title className={className}>{l10n('threshold')}</Title>
            <Description className={className}>
              <Value>{highestValue} </Value>
              <Threshold>
                {displayUnit} / {threshold} {displayUnit}
              </Threshold>
            </Description>
          </Item>
        )}
        <TruncatedItem>
          <Title className={className}>{l10n('alertDescription')}</Title>
          <Tooltip content={description} position="bottom" minimal targetTagName="div">
            <Description className={className}>{description}</Description>
          </Tooltip>
        </TruncatedItem>
        {!isViewingAlert && (
          <OpenAlert to={viewAlertLink} className={className} onClick={handleDeselectAlert}>
            {l10n('openLinkText')} <NewWindowIcon />
          </OpenAlert>
        )}
      </Items>
    </StyledViewAlertHeader>
  );
};

const useAlertDetails = (selectedAlertId: Guid) => {
  const { data, isLoading, isSuccess } = useAlertById(selectedAlertId);
  const { data: alertResponseCategories } = useAlertResponseCategories();
  const { utcTriggeredTime, longMessage, alertTypeId, inputSnapshot, triggerSnaphot } = data ?? LOADING_ALERT;
  const variable = getVariable({ inputSnapshot, triggerSnaphot });
  const localTime = useLocalTimeCallback();

  return useMemo(
    () => ({
      data: {
        monitorName: variable?.input?.processName || '-',
        alertType: alertResponseCategories?.find(c => c.id === alertTypeId)?.name || '-',
        threshold: variable?.input?.threshold?.value,
        date: localTime(utcTriggeredTime).toLocaleString(DateTime.DATE_MED),
        time: localTime(utcTriggeredTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
        description: longMessage || '-',
        displayUnit: variable?.input?.displayUnitName || '-',
        highestValue: Math.max(...(variable?.trigger.values ?? [])),
      },
      isLoading,
      isSuccess,
    }),
    [
      variable?.input?.processName,
      variable?.input?.threshold?.value,
      variable?.input?.displayUnitName,
      variable?.trigger.values,
      alertResponseCategories,
      localTime,
      utcTriggeredTime,
      longMessage,
      isLoading,
      isSuccess,
      alertTypeId,
    ]
  );
};
