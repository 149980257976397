import { slice } from './slice';

export const {
  setDomainBoundariesMapControl,
  setConfiguration,
  toggleAutoUpdate,
  modelsLoaded,
  modelCreatedOrModified,
  modelDeleted,
  displayEmissionSourcesToggled,
  modellingTimeChanged,
  selectedIntervalChanged,
  toggleActiveModel,
} = slice.actions;
