import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { useLogout } from 'app/business-logic/services/security-service';
import { Icon } from 'app/components/icon';
import { useHasAccess } from 'app/hooks/use-has-access';
import { useIsTrajectoryExport } from 'app/hooks/useIsTrajectoryExport';
import { useAppSelector } from 'app/redux/store';
import SideBar from 'app/views/components/navigation-bar/SideBar';
import { INavItem } from 'app/views/components/navigation-bar/NavItem';
import { NavWrapper } from 'app/views/Layout.styles';
import { AppSettings } from 'core/AppSettings';
import { useI18n } from 'core/hooks/useI18n';
import { Routing, useRouting } from 'core/routing/Routing';
import { useMemo } from 'react';
import urlJoin from 'url-join';
import { BeamerIcon, useHandleBeamerVisibility } from 'app/views/components/beamer';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';

const FROM = '?from=omnis';

export const NavigationBar = () => {
  const topItems = useTopItems();
  const bottomItems = useBottomItems();
  const isTrajectoryExport = useIsTrajectoryExport();
  if (isTrajectoryExport) return null;

  return (
    <NavWrapper>
      <SideBar topItems={topItems} bottomItems={bottomItems} />
    </NavWrapper>
  );
};

export function useTopItems() {
  const { l10n } = useI18n('app/views/components', 'shell.menu');
  const {
    facility: { companyAlias, facilityAlias },
  } = useAppSelector(state => state.profile);
  const { preferredMonitoringView } = useAppSelector(state => state.monitoring);
  const hasAccess = useHasAccess();
  const {
    routes: { monitoring, grid, modelling, alerts, trajectories, incidentIntelligence, blasting, annotations, reports },
  } = useRouting();

  return useMemo(() => {
    if (!companyAlias || !facilityAlias) return [];
    const items: INavItem[] = [
      {
        key: 'overview',
        title: l10n('overview'),
        icon: Icon.Dashboard,
        link: urlJoin(AppSettings.es2Url, 'dashboard', companyAlias, facilityAlias, FROM),
        accessControls: [KnownAccessControls.app.dashboard._],
        isExternal: true,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-overview-link'],
        },
      },
      {
        key: 'monitoring',
        title: l10n('monitoring'),
        icon: Icon.Monitoring,
        link: preferredMonitoringView === 'MAP' ? monitoring.realTime() : grid.realTime(),
        accessControls: [KnownAccessControls.app.monitoring._],
        path: preferredMonitoringView === 'MAP' ? Routing.routes.monitoring.root : Routing.routes.grid.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-monitoring-link'],
        },
      },
      {
        key: 'modelling',
        title: l10n('modelling'),
        icon: Icon.Modelling,
        link: modelling.root,
        accessControls: [KnownAccessControls.app.modelling._],
        path: Routing.routes.modelling.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-modelling-link'],
        },
      },
      {
        key: 'alerts',
        title: l10n('alerts'),
        icon: Icon.Alerts,
        link: alerts.root,
        accessControls: [KnownAccessControls.app.alerts._],
        path: Routing.routes.alerts.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-alerts-link'],
        },
      },
      {
        key: 'trajectories',
        title: l10n('trajectories'),
        icon: Icon.Trajectories,
        link: trajectories.root,
        accessControls: [KnownAccessControls.app.modelling.trajectory._],
        path: Routing.routes.trajectories.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-trajectories-link'],
        },
      },
      {
        key: 'blasting',
        title: l10n('blasting'),
        icon: Icon.Blasting,
        link: blasting.root,
        accessControls: [KnownAccessControls.app.blasting._],
        path: Routing.routes.blasting.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-blasting-link'],
        },
      },
      {
        key: 'intelligence',
        title: l10n('incidentIntelligence'),
        icon: Icon.IncidentIntelligence,
        link: incidentIntelligence.root,
        accessControls: [KnownAccessControls.app.incidentIntelligence._],
        path: Routing.routes.incidentIntelligence.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-incident-intelligence-link'],
        },
      },
      {
        key: 'annotations',
        title: l10n('annotations'),
        icon: Icon.Annotations,
        link: annotations.root,
        accessControls: [KnownAccessControls.app.config.annotations.monitorPollutants._],
        path: Routing.routes.annotations.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-annotations-link'],
        },
      },
      {
        key: 'reports',
        title: l10n('reports'),
        icon: Icon.Reports,
        link: reports.root,
        accessControls: [KnownAccessControls.app.reports._],
        path: Routing.routes.reports.root,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-reports-link'],
        },
      },
    ].filter(item => hasAccess(item.accessControls ?? []));
    return items;
  }, [
    companyAlias,
    facilityAlias,
    l10n,
    preferredMonitoringView,
    monitoring,
    grid,
    modelling.root,
    alerts.root,
    trajectories.root,
    blasting.root,
    incidentIntelligence.root,
    annotations.root,
    reports.root,
    hasAccess,
  ]);
}

export function useBottomItems(): INavItem[] {
  const { l10n } = useI18n('app/views/components', 'shell.menu');
  const hasAccess = useHasAccess();
  const {
    routes: { configuration, profile },
  } = useRouting();
  const handleLogout = useLogout();
  const { handleShowBeamer } = useHandleBeamerVisibility();
  const nonGuestItems = useMemo(() => {
    if (AppSettings.useGuestApiKey) return [];
    const items: INavItem[] = [
      {
        key: 'updates',
        title: l10n('updates'),
        icon: BeamerIcon,
        elementProps: {
          as: 'button',
          onClick: handleShowBeamer,
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-updates-link'],
        },
        isHidden: !AppSettings.beamerProductId,
      },
      {
        key: 'support',
        title: l10n('support'),
        icon: Icon.Support,
        link: 'https://support.envirosuite.com',
        accessControls: [],
        isExternal: true,
        elementProps: {
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-support-link'],
        },
      },
      {
        key: 'logout',
        title: l10n('logout'),
        icon: Icon.Logout,
        accessControls: [],
        elementProps: {
          onClick: handleLogout,
          as: 'button',
          'data-tracking': DATA_TRACKING_KEY['main-nav-bar-logout-link'],
        },
      },
    ];
    return items;
  }, [handleLogout, l10n, handleShowBeamer]);
  return useMemo(
    () =>
      [
        {
          key: 'siteConfig',
          title: l10n('siteConfig'),
          icon: Icon.SiteConfig,
          link: configuration.root,
          accessControls: [KnownAccessControls.app.config._],
          path: Routing.routes.configuration.root,
          elementProps: {
            'data-tracking': DATA_TRACKING_KEY['main-nav-bar-site-config-link'],
          },
        },
        {
          key: 'userSettings',
          title: l10n('userSettings'),
          icon: Icon.UserSettings,
          link: profile.root,
          path: Routing.routes.profile.root,
          elementProps: {
            'data-tracking': DATA_TRACKING_KEY['main-nav-bar-user-settings-link'],
          },
        },
        ...nonGuestItems,
      ].filter(item => hasAccess(item.accessControls ?? [])),
    [l10n, configuration.root, profile.root, nonGuestItems, hasAccess]
  );
}
