import { ButtonProps, DataAttributeProps } from 'app/types';
import PauseIcon from 'app/visuals/images/icons/pause.svg?react';
import PlayIcon from 'app/visuals/images/icons/play.svg?react';
import RewindIcon from 'app/visuals/images/icons/rewind.svg?react';
import SkipIcon from 'app/visuals/images/icons/skip.svg?react';
import { StyledButton } from './PlaybackControlButton.styles';

export type PlaybackControlButtonProps = {
  title: string;
  icon: PlaybackControlButtonIcon;
  status?: PlaybackControlButtonStatus;
  buttonProps?: ButtonProps & DataAttributeProps;
  className?: string;
};

export enum PlaybackControlButtonIcon {
  play = 'play',
  pause = 'pause',
  rewind = 'rewind',
  skip = 'skip',
}

export enum PlaybackControlButtonStatus {
  idle = 'idle',
  active = 'active',
  buffering = 'buffering',
}

export const PlaybackControlButton = ({
  title,
  icon,
  status = PlaybackControlButtonStatus.idle,
  buttonProps = {},
  className,
}: PlaybackControlButtonProps) => {
  const { className: buttonPropsClassName } = buttonProps;
  const combinedClassName = [className, buttonPropsClassName].filter(Boolean).join(' ');
  return (
    <StyledButton aria-label={title} $status={status} $icon={icon} {...buttonProps} className={combinedClassName}>
      <Icon icon={icon} />
    </StyledButton>
  );
};

const Icon = ({ icon }: { icon: PlaybackControlButtonIcon }) => {
  switch (icon) {
    case PlaybackControlButtonIcon.play:
      return <PlayIcon />;
    case PlaybackControlButtonIcon.pause:
      return <PauseIcon />;
    case PlaybackControlButtonIcon.rewind:
      return <RewindIcon />;
    case PlaybackControlButtonIcon.skip:
      return <SkipIcon />;
    default:
      return null;
  }
};
