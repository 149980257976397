import { Tooltip } from '@blueprintjs/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { NoiseEvent } from 'app/business-logic/services/noise-service';
import { NoiseEventClassification } from 'app/business-logic/services/noise-service/noiseService.types';
import { Checkbox } from 'app/components/checkbox';
import { FieldValidationError } from 'app/components/form/field-validation-error';
import { AccessControlGate } from 'app/routing/access-control-gate';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';
import { useI18n } from 'core/hooks/useI18n';
import { Form, Formik, useFormikContext } from 'formik';
import { useEventClassificationInitialState } from '../../hooks/use-event-classification-initial-state';
import {
  ButtonsWrapper,
  ClassificationCommentsField,
  ClassificationCommentsWrapper,
  ClassificationsWrapper,
  ConfirmButton,
  DiscardButton,
  DisregardWrapper,
  EventClassificationHeading,
  HiddenLabel,
  OptionsWrapper,
} from './EventClassification.styles';
import { ClassificationButton } from './classification-button';
import { useClassifyNoiseEvent } from './useClassifyNoiseEvent';

export type EventClassificationState = {
  classifications: {
    isSelected: boolean;
    classification: NoiseEventClassification;
  }[];
  isRelevant: boolean;
  comments: string;
};

type EventClassificationProps = { event: NoiseEvent; handleExpansion: () => void };

export const EventClassification = (props: EventClassificationProps) => {
  const { event, handleExpansion } = props;
  const { initialState, isFetching: initialStateIsLoading } = useEventClassificationInitialState({ event });
  const { classifyNoiseEvent, isPending: isSubmitting } = useClassifyNoiseEvent({
    event,
    onSuccess: () => handleExpansion(),
  });
  return (
    <AccessControlGate requires={KnownAccessControls.app.noise.classification.data.write._}>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        onSubmit={values => {
          classifyNoiseEvent(values);
        }}
      >
        <Form>
          <EventClassificationFields
            initialStateIsLoading={initialStateIsLoading}
            isSubmitting={isSubmitting}
            {...props}
          />
        </Form>
      </Formik>
    </AccessControlGate>
  );
};

const EventClassificationFields = ({
  event,
  initialStateIsLoading,
  isSubmitting,
  handleExpansion,
}: {
  initialStateIsLoading: boolean;
  isSubmitting: boolean;
  handleExpansion: () => void;
} & EventClassificationProps) => {
  const { l10n } = useI18n('app/views/alerts', 'eventClassification');
  const { values, setFieldValue, dirty, resetForm } = useFormikContext<EventClassificationState>();
  const commentLength = 1000;
  const isCommentTooLong = values.comments.length > commentLength;
  const setIsDisregarded = (isDisregarded: boolean) => {
    const key: keyof EventClassificationState = 'isRelevant';
    const value: EventClassificationState[typeof key] = !isDisregarded;
    setFieldValue(key, value);
  };
  const setComments = (comments: string) => {
    const key: keyof EventClassificationState = 'comments';
    const value: EventClassificationState[typeof key] = comments;
    setFieldValue(key, value);
  };
  const isLoading = initialStateIsLoading || isSubmitting;
  return (
    <>
      <EventClassificationHeading>{l10n('heading.eventClassification')}</EventClassificationHeading>
      <ClassificationsWrapper>
        {values.classifications.map(classification => (
          <ClassificationButton key={classification.classification.id} isLoading={isLoading} {...classification} />
        ))}
      </ClassificationsWrapper>
      <EventClassificationHeading>{l10n('heading.comments')}</EventClassificationHeading>
      <ClassificationCommentsWrapper>
        <HiddenLabel htmlFor={`comments-${event.id}`}>{l10n('labels.comments')}</HiddenLabel>
        <ClassificationCommentsField
          $invalid={isCommentTooLong}
          id={`comments-${event.id}`}
          value={values.comments ?? ''}
          onChange={e => {
            setComments(e.target.value);
          }}
          disabled={isLoading}
          placeholder={l10n('commentsPlaceholder')}
          data-tracking={DATA_TRACKING_KEY['event-history-comments']}
        />
        <OptionsWrapper>
          <DisregardWrapper>
            <Checkbox
              label={l10n('labels.disregardEvent')}
              checked={!values.isRelevant}
              disabled={isLoading}
              onChange={e => {
                setIsDisregarded(e.target.checked);
              }}
              data-tracking={DATA_TRACKING_KEY['event-history-disregard-button']}
            />
            <Tooltip content={l10n('tooltips.disregardEvent')} placement="right">
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-tracking={DATA_TRACKING_KEY['event-history-disregard-tooltip']}
              />
            </Tooltip>
          </DisregardWrapper>
        </OptionsWrapper>
      </ClassificationCommentsWrapper>

      {isCommentTooLong && (
        <FieldValidationError>{l10n('validation.length', { length: commentLength })}</FieldValidationError>
      )}
      <ButtonsWrapper>
        <ConfirmButton
          $variant="outlined"
          type="submit"
          disabled={!dirty || isLoading || isCommentTooLong}
          data-tracking={DATA_TRACKING_KEY['event-history-save-button']}
        >
          {l10n('actions.confirm')}
        </ConfirmButton>
        <DiscardButton
          $variant="text"
          onClick={e => {
            e.preventDefault();
            resetForm();
            handleExpansion();
          }}
          data-tracking={DATA_TRACKING_KEY['event-history-discard-button']}
        >
          {l10n('actions.discard')}
        </DiscardButton>
      </ButtonsWrapper>
    </>
  );
};
