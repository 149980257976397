import { PlaybackControlButtonIcon } from 'app/components/playback';
import { useWaveSurfer, WaveSurferOptions } from 'app/hooks/use-wavesurfer';
import { useAppSelector } from 'app/redux/store';
import { useThemeContext } from 'app/theme/useThemeContext';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';
import { useI18n } from 'core/hooks/useI18n';
import { Duration } from 'luxon';
import { memo, MouseEventHandler, useCallback, useEffect, useMemo } from 'react';
import { EventNoisePlaybackSlider } from '../event-noise-playback-slider';
import { EventNoisePlaybackProvider, useEventNoisePlaybackContext } from './EventNoisePlaybackContext';
import { getSeekToProgressOnClick } from './getSeekToProgressOnClick';
import { SpectrogramPlaybackContainer } from './SpectrogramPlaybackContainer';
import { SAMPLE_RATE, WAVESURFER_PLAYBACK_HEIGHT } from './WaveSurferConstants';
import {
  AudioDuration,
  Controls,
  PlayPause,
  Timestamp,
  TimeWrapper,
  WaveSurferPlaybackContainer,
  WaveSurferPlaybackLoading,
} from './WaveSurferPlayback.styles';
import { WaveSurferScrubber } from './WaveSurferScrubber';

export interface WaveSurferPlaybackProps {
  audio?: string | Blob;
  isFetching: boolean;
}
export const WaveSurferPlayback = (props: WaveSurferPlaybackProps) => {
  const options = useWaveSurferOptions();
  return (
    <EventNoisePlaybackProvider options={options}>
      <Playback {...props} />
    </EventNoisePlaybackProvider>
  );
};

const Playback = memo(({ audio, isFetching }: WaveSurferPlaybackProps) => {
  const { containerRef, playPause, isPlaying, isLoading, millisecondsTimestamp, durationMilliseconds, seekTo } =
    useEventNoisePlaybackContext();
  const { l10n } = useI18n('app/views/alerts', 'playback');
  const { eventAudioVisualisation } = useAppSelector(state => state.ui);
  const { timestamp, duration } = usePlaybackTime(millisecondsTimestamp, durationMilliseconds);
  const handleSpectrogramClick = useOnSpectrogramClick(seekTo);

  useLoadAudio(audio);
  usePauseAudioOnDrawerClose();
  useWaveformVisualisation();

  return (
    <>
      <WaveSurferPlaybackContainer
        ref={containerRef}
        $visualisation={eventAudioVisualisation}
        onClick={handleSpectrogramClick}
        data-tracking={DATA_TRACKING_KEY['event-history-wave-surfer-playback']}
      >
        {(isLoading || isFetching) && <WaveSurferPlaybackLoading />}
        {eventAudioVisualisation === 'spectrogram' && (
          <WaveSurferScrubber
            isPlaying={isPlaying}
            seekTo={seekTo}
            duration={durationMilliseconds}
            timestamp={millisecondsTimestamp}
            width={containerRef.current ? containerRef.current.clientWidth : 0}
          />
        )}
        <SpectrogramPlaybackContainer />
      </WaveSurferPlaybackContainer>
      {!isLoading && !isFetching && (
        <>
          <EventNoisePlaybackSlider />
          <Controls>
            <PlayPause
              buttonProps={{
                onClick: playPause,
                disabled: isLoading,
                'data-tracking': isPlaying
                  ? DATA_TRACKING_KEY['event-history-playback-pause-button']
                  : DATA_TRACKING_KEY['event-history-playback-play-button'],
              }}
              title={isPlaying ? l10n('pause') : l10n('play')}
              icon={isPlaying ? PlaybackControlButtonIcon.pause : PlaybackControlButtonIcon.play}
            />
            <TimeWrapper>
              <Timestamp>{timestamp}</Timestamp>/<AudioDuration>{duration}</AudioDuration>
            </TimeWrapper>
          </Controls>
        </>
      )}
    </>
  );
});

Playback.displayName = 'WaveSurferPlayback_Playback';

function usePauseAudioOnDrawerClose() {
  const { pause } = useEventNoisePlaybackContext();
  const { selectedAlert } = useAppSelector(state => state.ui);
  const isDrawerOpen = !!selectedAlert;
  useEffect(() => {
    if (!isDrawerOpen) pause();
  }, [isDrawerOpen, pause]);
}

function useLoadAudio(audio?: string | Blob) {
  const { load, loadBlob } = useEventNoisePlaybackContext();
  useEffect(() => {
    if (!audio) return;
    if (typeof audio === 'string') load(audio);
    else loadBlob(audio);
  }, [load, loadBlob, audio]);
}

function useOnSpectrogramClick(seekTo: ReturnType<typeof useWaveSurfer>['seekTo']): MouseEventHandler<HTMLDivElement> {
  const { eventAudioVisualisation } = useAppSelector(state => state.ui);
  return useCallback(
    event => {
      if (eventAudioVisualisation !== 'spectrogram') return;
      const progress = getSeekToProgressOnClick(event);
      seekTo(progress);
    },
    [eventAudioVisualisation, seekTo]
  );
}

function useWaveSurferOptions(): WaveSurferOptions {
  const {
    colors: {
      mono: { text01, ui05 },
    },
  } = useThemeContext();
  return {
    height: WAVESURFER_PLAYBACK_HEIGHT,
    normalize: true,
    progressColor: text01,
    waveColor: ui05,
    sampleRate: SAMPLE_RATE,
  };
}

function usePlaybackTime(millisecondsTimestamp: number, durationMilliseconds: number | undefined) {
  const timestamp = useMemo(
    () => Duration.fromMillis(millisecondsTimestamp).toFormat('mm:ss'),
    [millisecondsTimestamp]
  );
  const duration = useMemo(() => {
    if (typeof durationMilliseconds !== 'number') return;
    return Duration.fromMillis(durationMilliseconds).toFormat('mm:ss');
  }, [durationMilliseconds]);
  return {
    timestamp,
    duration,
  };
}

/**
 * This hook is used to control the visualisation of the waveform
 * because there is no direct way to control it through the WaveSurfer instance
 */
const useWaveformVisualisation = () => {
  const { waveSurferRef } = useEventNoisePlaybackContext();
  const { eventAudioVisualisation } = useAppSelector(state => state.ui);
  const waveSurfer = waveSurferRef.current;
  useEffect(() => {
    if (!waveSurfer) return;

    if (eventAudioVisualisation === 'spectrogram') {
      // If the visualisation is set to spectrogram, set the height of the waveform to 0 to hide it
      waveSurfer.setOptions({ height: 0 });
    }
    if (eventAudioVisualisation === 'waveform') {
      // If the visualisation is set to waveform, set the height of the waveform to the default height
      waveSurfer.setOptions({ height: WAVESURFER_PLAYBACK_HEIGHT });
    }
  }, [eventAudioVisualisation, waveSurfer]);
};
